import React from "react"

const Image = ({ media, image, className, alt }) => (
  <img
    className={className ? className : null}
    src={media ? media[image.id] : ""}
    alt={alt ? alt : ""}
  />
)

export default Image
