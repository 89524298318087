import React from "react"
import Icon from "./Icon"
import Image from "./Image"
import PropTypes from "prop-types"

const AbstractMedia = props => {
  const { media, abstractMedia, className, alt } = props

  return (
    <>
      {!!abstractMedia && abstractMedia.icon && (
        <Icon icon={abstractMedia.icon} className={className} />
      )}
      {!!abstractMedia && abstractMedia.image && (
        <Image
          image={abstractMedia.image}
          media={media}
          className={className}
          alt={alt}
        />
      )}
    </>
  )
}

AbstractMedia.propTypes = {
  abstractMedia: PropTypes.object,
  media: PropTypes.object,
  className: PropTypes.string,
  alt: PropTypes.string,
}

AbstractMedia.defaultProps = {
  abstractMedia: {},
}

export default AbstractMedia
